import type { PublicUser } from '@sbt-web/auth';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import {
  AdTypes,
  AdvertiserType,
  AnubisClient,
  BuyerInfo,
  CategoryId,
  CategoryStore,
  HTTPStatusCode,
  HermesClient,
  type AdItem,
  type FileSpec,
  type RecommendedSearch,
  type RecommendedSearchFilter,
} from '@sbt-web/networking';
import { Button, Headline4, Icon } from '@sbt-web/ui';
import { adTypesToSlugMap } from '@tools/search/values';
import classNames from 'classnames';
import React, {
  type FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMessageValidation } from './hooks/useMessageValidation';
import {
  clickRecommendationSearchEvent,
  sendFormButtonClick,
  sendMessageSuccessEvent,
  sendMessageSuccessGTMEvent,
  viewRecommendationSearchEvent,
} from '../../tracking';
import type { ErrorCode, SearchData } from '../../types';
import { ERRORS_CODE, getError } from './utils/errors';
import { HADES_PATH } from '@shared/constants';
import {
  AttachmentField,
  ConfirmMessage,
  ErrorDialog,
  MessageField,
  PhoneForwarder,
} from './components';

import styles from './form.module.scss';

interface FormProps {
  isMobile: boolean;
  onCloseContactForm: () => void;
  isPro: boolean;
  category: string;
  enableAttachments: boolean;
  userId: string;
  advertiserName: string;
  subject: string;
  adUrn: string;
  requestLogin: () => Promise<PublicUser>;
  item: AdItem;
  onSendReplyCallback?: () => Promise<void>;
  buyerName?: string;
  search?: SearchData;
}

interface SuccessEvent {
  isPhoneTrack: boolean;
  phone: string;
  forwardPhone?: boolean;
  search?: SearchData;
}

export interface TrackingSendFormData {
  cv: boolean | null;
  phone: boolean | null;
  editText: boolean;
}

const JOB_OFFERS_CATEGORY_ID = '26';

const dispatchSuccessEvent = ({
  isPhoneTrack,
  forwardPhone,
  phone,
  search,
}: SuccessEvent): void => {
  // this event cannot be removed. It's used on GTM and Prebid scripts.
  window.dispatchEvent(
    new CustomEvent('subito:AdReply:Success', {
      detail: {
        forwardPhone,
        isPhoneTrack,
        phone,
        search,
        disabled: true,
      },
    })
  );
};

export const Form = ({
  isMobile,
  onCloseContactForm,
  isPro,
  category,
  enableAttachments,
  userId,
  advertiserName,
  subject,
  adUrn,
  onSendReplyCallback,
  requestLogin,
  buyerName,
  search,
  item,
}: FormProps) => {
  const shouldRenderPhoneForwarder = useMemo(
    () => isPro && category !== JOB_OFFERS_CATEGORY_ID,
    [isPro, category]
  );

  const hermesClient = useMemo(() => new HermesClient(HADES_PATH, 'web'), []);
  const anubisClient = useMemo(() => new AnubisClient(HADES_PATH, 'web'), []);

  const [buyerPhone, setBuyerPhone] = useState<string | undefined>(undefined);
  const [curriculum, setCurriculum] = useState<FileSpec | undefined>(undefined);
  const initialMessage = `Ciao ${advertiserName}, ti contatto per l'annuncio ${subject}, `;
  const [message, setMessage] = useState<string>(initialMessage);
  const [replyDone, setReplyDone] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const { errorMessage, isValid } = useMessageValidation();

  const [errors, setErrors] = useState<string[]>([]);
  const submitRef = useRef<boolean>(false);
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [recommendationSearch, setRecommendationSearch] = useState<{
    count: number;
    url: string;
  }>();

  const checkRelated = useCallback(
    async (urn: string) => {
      const res = await anubisClient.getRecommendedSearch(urn);

      const status = res.status;
      if (status !== HTTPStatusCode.OK) return;

      const payload: RecommendedSearch | undefined = res.payload;
      if (!payload) return;

      const { count, filters } = payload;
      if (!count) return;

      const url = buildRecommendedUrlFromFilter(filters);
      setRecommendationSearch({ count, url });
    },
    [anubisClient]
  );

  useEffect(() => {
    if (
      item.category.id === CategoryId.Auto &&
      item.advertiser.type === AdvertiserType.Private
    ) {
      checkRelated(item.urn);
    }
  }, [item, checkRelated]);

  useEffect(() => {
    let didCancel = false;
    (async () => {
      const { buyerInfo, status } = await hermesClient.getBuyerInfo(userId);

      if (!didCancel && status === HTTPStatusCode.OK && buyerInfo) {
        setBuyerPhone(buyerInfo.phone);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [userId, hermesClient, buyerName]);

  const handleSetPhoneNumber = (phoneNumber: string) =>
    setBuyerPhone(phoneNumber);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!submitRef.current && isValid(message)) {
      submitRef.current = true;
      sendAdreply(userId);
    }
  };

  const sendDoneTracking = (buyerInfo: BuyerInfo) => {
    const isEditMessage = initialMessage.trim() !== message.trim();
    const trackingData: TrackingSendFormData = {
      cv: enableAttachments ? !!curriculum : null,
      phone: shouldRenderPhoneForwarder ? !!buyerInfo.phone : null,
      editText: isEditMessage,
    };

    sendFormButtonClick(trackingData);
  };

  const buildBuyerInfo = (
    buyerId: string,
    hermesBuyerInfo?: BuyerInfo
  ): BuyerInfo => {
    const name =
      buyerId === userId
        ? (buyerName ?? hermesBuyerInfo?.name ?? '')
        : (hermesBuyerInfo?.name ?? '');
    return {
      ...hermesBuyerInfo,
      userId: buyerId,
      email: hermesBuyerInfo?.email ?? '',
      name,
      forwardPhone:
        buyerPhone !== undefined ? !!buyerPhone : hermesBuyerInfo?.forwardPhone,
      phone: buyerPhone ?? hermesBuyerInfo?.phone,
    };
  };

  const trackSendAdReply = () => {
    optimizely?.onReady().then(() => {
      if (!userId) return;
      optimizely.track('Send_Message_Polaris');
      optimizely.track('Send_Message', userId, {
        adURN: item.urn,
      });
      optimizely.track('PayPal_Send_Message', userId, {
        adURN: item.urn,
      });
      if (item.category.id === CategoryId.Auto && isPro) {
        optimizely.track('pro_leads_web');
        optimizely.track('send_message_web');
      }
    });
  };

  const sendAdreply = async (buyerId: string) => {
    const { buyerInfo: hermesBuyerInfo } =
      await hermesClient.getBuyerInfo(buyerId);

    //check if user is logged in with the same account
    const buyerInfo = buildBuyerInfo(buyerId, hermesBuyerInfo);

    const { status, payload } = await hermesClient.sendAdReply(
      adUrn,
      message,
      {
        ...buyerInfo,
      },
      curriculum
    );

    submitRef.current = false;

    switch (status) {
      case HTTPStatusCode.BadRequest:
        handleBadRequest(payload);
        break;

      case HTTPStatusCode.OK:
        handleSuccessRequest(buyerInfo);
        break;

      case HTTPStatusCode.Unauthorized: {
        handleUnauthorized();
        break;
      }

      default:
        setShowErrorDialog(true);
        break;
    }
  };

  const handleBadRequest = (payload: unknown) => {
    if (payload && typeof payload === 'object' && 'errors' in payload) {
      const sendAdReplyErrors = (payload.errors as ErrorCode[]).map(
        (error) => error.error_code
      );
      const isErrorWithLabel = getError(ERRORS_CODE, sendAdReplyErrors);

      if (isErrorWithLabel) {
        setErrors(sendAdReplyErrors);
      } else {
        setShowErrorDialog(true);
      }
    }
  };

  const handleSuccessRequest = (buyerInfo: BuyerInfo) => {
    setReplyDone(true);
    trackSendAdReply();
    dispatchSuccessEvent({
      forwardPhone: buyerInfo.forwardPhone,
      isPhoneTrack: shouldRenderPhoneForwarder,
      phone: buyerInfo.phone ?? '',
      search,
    });
    sendDoneTracking(buyerInfo);
    sendMessageSuccessGTMEvent();
    sendMessageSuccessEvent({
      item,
      forwardPhone: buyerInfo.forwardPhone,
      isPhoneTrack: shouldRenderPhoneForwarder,
      phone: buyerInfo.phone ?? '',
      search,
    });
    onSendReplyCallback?.();
  };

  const handleUnauthorized = async () => {
    const { id } = await requestLogin();

    if (id) {
      sendAdreply(id);
    }
  };

  const handleConfirmMessageCtaClick = () => {
    if (!recommendationSearch) {
      onCloseContactForm();
    } else {
      clickRecommendationSearchEvent(recommendationSearch.count);
      window.location.assign(recommendationSearch.url);
    }
  };

  useEffect(() => {
    if (recommendationSearch && replyDone) {
      viewRecommendationSearchEvent(recommendationSearch.count);
    }
  }, [replyDone, recommendationSearch]);

  return (
    <>
      <div className={styles.formWrapper}>
        {!isMobile && (
          <Button
            aria-label="Chiudi"
            onClick={onCloseContactForm}
            type="text"
            icon={<Icon name="Close" />}
            classes={[styles.closeButton]}
          />
        )}
        <div
          id="adreply_form_container"
          className={classNames(styles.container, {
            [styles.hide]: replyDone,
          })}
        >
          {!isMobile && <Headline4>Contatta l&apos;utente</Headline4>}
          <form
            method="post"
            name="adreply_form"
            onSubmit={handleSubmit}
            className={styles.form}
          >
            <MessageField
              message={message}
              onChangeMessage={(newMessage: string) => {
                isValid(newMessage);
                setMessage(newMessage);
              }}
              errorMessage={errorMessage}
            />

            {shouldRenderPhoneForwarder && (
              <PhoneForwarder
                phone={buyerPhone}
                onSetPhoneNumber={handleSetPhoneNumber}
                isMobile={isMobile}
              />
            )}
            {enableAttachments && (
              <AttachmentField
                name="curriculum"
                placeholder="Carica file"
                onChange={(file) => setCurriculum(file)}
                errors={errors}
              />
            )}
            <Button htmlType="submit" classes={[styles.submitButton]}>
              Invia
            </Button>
          </form>
        </div>

        {replyDone && (
          <ConfirmMessage
            advertiserName={advertiserName}
            isVisible={replyDone}
            categoryId={item.category.id}
            onClick={handleConfirmMessageCtaClick}
            recommendedSearch={!!recommendationSearch}
          />
        )}
        <div
          id="apn_box_adreply"
          style={{ display: replyDone ? 'block' : 'none' }}
        />
      </div>

      <ErrorDialog
        isOpened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        onRetry={() => {
          setShowErrorDialog(false);
          sendAdreply(userId);
        }}
      />
    </>
  );
};

export function buildRecommendedUrlFromFilter(
  filters: RecommendedSearchFilter[]
) {
  const filterMap: Record<string, RecommendedSearchFilter> = filters.reduce(
    (map, filter) => ({ ...map, [filter.uri]: filter }),
    {}
  );

  const place = filterMap['/geo/region']?.values[0].metadata?.friendly_name;
  const adTypeKey = filterMap['/type']?.values[0].key as AdTypes;
  const adType = adTypesToSlugMap[adTypeKey];
  const category = CategoryStore.getCategoryById(
    filterMap['/category']?.values[0].key as CategoryId
  ).friendly;

  const recommendationUrl = new URL(
    `${process.env.NEXT_PUBLIC_ENV_BASE_URL}/annunci-${place}/${adType}/${category}/`
  );

  const advType = filterMap['/advertiser_type']?.values[0].key;
  const carBrand = filterMap['/car/brand']?.values[0].key;
  const carModel = filterMap['/car/model']?.values[0].key;
  const priceMin = filterMap['/price/min']?.values[0].key;
  const priceMax = filterMap['/price/max']?.values[0].key;

  recommendationUrl.searchParams.set('advt', advType);
  recommendationUrl.searchParams.set('cb', carBrand);
  recommendationUrl.searchParams.set('cm', carModel);
  recommendationUrl.searchParams.set('ps', priceMin);
  recommendationUrl.searchParams.set('pe', priceMax);

  return recommendationUrl.toString();
}
