import React from 'react';
import { BodyText, Button, ButtonLink } from '@sbt-web/ui';
import classes from './index.module.scss';

interface SocializationProps {
  adsCount?: number;
  url?: string;
}

const SocializationB = ({ adsCount, url }: SocializationProps) =>
  adsCount &&
  url && (
    <aside className={classes.socializationB}>
      <div className={classes['online_ads']}>
        <BodyText size="small" classes={[classes.body_text]}>
          {`${adsCount ?? 0} annunc${adsCount === 1 ? 'io' : 'i'} online`}
        </BodyText>
      </div>
      <div className={classes.actions}>
        <ButtonLink type="outline" href={url} size="medium">
          Vai allo shop
        </ButtonLink>
        <Button
          type="solid-lite"
          size="medium"
          classes={[classes.contactButton]}
          onClick={() => {
            window.dispatchEvent(
              new CustomEvent('subito:AdView:BtnAuthorReply')
            );
          }}
        >
          Contatta
        </Button>
      </div>
    </aside>
  );

SocializationB.displayName = 'BottomShopBox_Socialization';

export default SocializationB;
