import React from 'react';
import { SmartAdSenseBlock } from '@client/components/Search/Containers/SmartAdvItem';
import { DesktopBottom } from '@client/components/Adv/AdSense/adDetails/adUnits/desktop-bottom';
import type { CategoryId } from '@sbt-web/networking';

export const AdsAdDetailsBlock = function ({
  listingCategory,
}: {
  listingCategory: CategoryId;
}) {
  return (
    <div
      className="adsense-bottom-container"
      style={{ margin: '20px', width: '620px' }}
    >
      <SmartAdSenseBlock
        listingCategory={listingCategory}
        variations={DesktopBottom}
        fallbackAdUnitId="desktop-bottom-addetail"
      />
    </div>
  );
};
