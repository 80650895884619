import { getPublicUser } from '@sbt-web/auth';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import {
  AdItem,
  BuyerInfo,
  HTTPStatusCode,
  HermesClient,
} from '@sbt-web/networking';
import { getCategory, getPublisher } from '@sbt-web/tracking';
import {
  BodyText,
  Button,
  FullModal,
  Headline5,
  Headline6,
  NotificationsContainer,
  TextField,
} from '@sbt-web/ui';
import { urnTransformer } from '@sbt-web/utils';
import { getCategoryThemeById } from '@shared/helpers/Themes';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { HADES_PATH } from '@shared/constants';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classes from './index.module.scss';

export type MakeYourPriceProps = {
  item: AdItem;
};

const MIN_PRICE_PERCENTAGE_DIFFERENCE = 30;

export const MakeYourPrice = ({ item }: MakeYourPriceProps) => {
  const [open, setOpen] = useState(false);
  const [proposedPrice, setProposedPrice] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const categoryId = item.category.id;
  const imagePath = `/static/img/ad-reply/thankyou-${getCategoryThemeById(categoryId)}.svg`;
  const hermesClient = useMemo(() => new HermesClient(HADES_PATH, 'web'), []);
  const NotificationsRef = useRef<NotificationsContainer>(null);

  const initialPrice = item.features['/price']?.values?.[0]?.key ?? '0';
  const { optimizely } = useContext(OptimizelySubitoContext);

  useEffect(() => {
    if (!optimizely) return;

    const handleMakeYourPriceShow = () => {
      setOpen(true);
      optimizely.onReady().then(() => {
        optimizely.track('cta_fai_una_proposta_web');
      });
    };

    window.addEventListener(
      'subito:AdReply:OpenMakeYourPriceDialog',
      handleMakeYourPriceShow
    );

    return () => {
      window.removeEventListener(
        'subito:AdReply:OpenMakeYourPriceDialog',
        handleMakeYourPriceShow
      );
    };
  }, [optimizely]);

  const sendNotification: NotificationsContainer['push'] = (text, type) => {
    if (NotificationsRef.current) {
      NotificationsRef.current.push(text, type);
    }
  };

  const checkPrice = useCallback(
    (proposedPrice: string) => {
      const pPrice = parseInt(proposedPrice);
      const iPrice = parseInt(initialPrice);
      const minPrice =
        iPrice - (MIN_PRICE_PERCENTAGE_DIFFERENCE / 100) * iPrice;
      if (pPrice < minPrice) {
        setErrorMessage(
          'Il prezzo proposto è troppo basso, ti consigliamo di aumentarlo'
        );
      } else {
        setErrorMessage('');
      }
    },
    [initialPrice]
  );

  const sendAdreply = async () => {
    const user = getPublicUser();
    if (!user) {
      return;
    }
    const userId = user.id;
    const buyerName = user.name;
    const { buyerInfo: hermesBuyerInfo } =
      await hermesClient.getBuyerInfo(userId);

    const buyerInfo: BuyerInfo = {
      ...hermesBuyerInfo,
      userId,
      email: hermesBuyerInfo?.email ?? '',
      name: buyerName ?? hermesBuyerInfo?.name ?? '',
    };

    const { status, payload } = await hermesClient.sendAdReply(
      item.urn,
      `L'acquirente ha offerto ${proposedPrice}€ per la tua auto. Valuta l'offerta e inizia una conversazione.`,
      buyerInfo
    );

    switch (status) {
      case HTTPStatusCode.BadRequest:
        if (payload?.errors) {
          sendNotification(
            'Si è verificato un errore. Riprova più tardi.',
            'error'
          );
        }
        break;

      case HTTPStatusCode.OK:
        trackEvents();
        setMessageSent(true);
        break;

      default:
        sendNotification(
          'Si è verificato un errore. Riprova più tardi.',
          'error'
        );
        break;
    }
  };

  const trackEvents = () => {
    optimizely?.onReady().then(() => {
      optimizely.track('pro_leads_web');
      optimizely.track('new_offer_proposal_web', {
        original_price: initialPrice,
        proposed_price: proposedPrice,
      });
    });

    const urn = item.urn;
    getOrCreatePulse()?.queueEvent({
      type: 'Send',
      intent: 'Request',
      object: {
        type: 'Message',
        inReplyTo: {
          '@id': `sdrn:subito:classified:${urn}`,
          '@type': 'ClassifiedAd',
          category: getCategory(item.category),
          name: item.subject,
          adId: urnTransformer(urn)?.adId,
          publisher: getPublisher(item.advertiser.userId, item.advertiser.type),
        },
        subject: 'pre-agreed',
      },
    });
  };

  useEffect(() => {
    checkPrice(proposedPrice);
  }, [proposedPrice, checkPrice]);

  const isInputEdited = useRef(false);

  const handleCloseIntention = () => {
    optimizely?.onReady().then(() => {
      optimizely.track('exit_modal_web', {
        keybord_input: `${isInputEdited.current}`,
      });
    });
    setOpen(false);
  };

  return (
    <FullModal
      open={open}
      onClose={handleCloseIntention}
      title={'Fai il tuo prezzo'}
      classes={[classes.transactionModal]}
    >
      {!messageSent ? (
        <section className={classes.container}>
          <NotificationsContainer
            ref={NotificationsRef}
            removeAfter={3}
            max={5}
            fixed={false}
            size="medium"
          />
          <div className={classes.proposalView}>
            <Headline5 classes={[classes.title]}>
              Proponi un nuovo prezzo
            </Headline5>
            <BodyText classes={[classes.description]}>
              Inserisci il prezzo a cui vorresti acquistare l’oggetto.
            </BodyText>
            <TextField
              name="item_price"
              type="number"
              prefix="€"
              size="medium"
              value={proposedPrice}
              inputHeaderProps={{ label: 'Nuovo prezzo' }}
              onInputChange={(e) => {
                setProposedPrice(e.target.value);
                isInputEdited.current = true;
              }}
              infoMessage={`Prezzo originale: ${initialPrice} €`}
              placeholder="Inserisci il prezzo"
            />
            {errorMessage.trim() !== '' && (
              <BodyText classes={[classes.warningMsg]}>{errorMessage}</BodyText>
            )}
          </div>
          <div className={classes.proposalButton}>
            <Button
              classes={[classes.primaryButton]}
              type="solid"
              disabled={!!errorMessage.trim() || proposedPrice.trim() === ''}
              onClick={sendAdreply}
              size="large"
            >
              Invia proposta
            </Button>
          </div>
        </section>
      ) : (
        <section className={classes.thankyouView}>
          <div>
            <img
              className={classes.image}
              src={imagePath}
              alt=""
              width="240"
              height="180"
            />
            <Headline6 element="h2" classes={[classes.title]}>
              Richiesta inviata
            </Headline6>
            <BodyText
              classes={[classes.description, classes.descriptionThankyou]}
            >
              Troverai la conversazione con{' '}
              {item.advertiser.shopName ?? item.advertiser.name} nella sezione
              Messaggi o nella tua casella di posta.
            </BodyText>
          </div>

          <div className={classes.thankyouButton}>
            <Button
              classes={[classes.primaryButton]}
              type="solid"
              onClick={() => setOpen(false)}
              size="large"
            >
              Chiudi
            </Button>
          </div>
        </section>
      )}
    </FullModal>
  );
};
