import { BodyText, Button, FullModal, TextField } from '@sbt-web/ui';
import React, { FormEvent, useState } from 'react';
import styles from './index.module.scss';

export type PhoneDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (phoneNumber: string) => void;
};

const ERROR_MESSAGE = 'Inserisci un numero di telefono valido';

export const isValidPhoneNumber = (phoneNumber: string): boolean =>
  /^((00|\+))?\d{6,15}$/.test(phoneNumber);

export const PhoneDialog = ({
  isOpened,
  onClose,
  onSubmit,
}: PhoneDialogProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);

  const validatePhoneNumber = (phone: string) =>
    setError(isValidPhoneNumber(phone) ? undefined : ERROR_MESSAGE);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isValidPhoneNumber(phoneNumber)) {
      onSubmit(phoneNumber);
      setPhoneNumber('');
    } else {
      setError(ERROR_MESSAGE);
    }
  };

  const handleClose = () => {
    setError(undefined);
    setPhoneNumber('');
    onClose();
  };

  return (
    <FullModal
      withClose={true}
      open={isOpened}
      onClose={handleClose}
      title="Telefono"
    >
      <form
        className={styles.body}
        method="POST"
        onSubmit={handleSubmit}
        name="phone_number_form"
      >
        <div className={styles.marginBottom}>
          <BodyText>
            Concludi l'affare più velocemente: aggiungi il tuo numero di
            telefono, sarà inoltrato insieme al tuo messaggio.
          </BodyText>
        </div>
        <div className={styles.marginBottom}>
          <TextField
            name="phone"
            placeholder="Inserisci il tuo numero"
            inputHeaderProps={{ label: 'Numero di telefono' }}
            value={phoneNumber}
            onInputChange={(e) => {
              setPhoneNumber(e.target.value);
              validatePhoneNumber(e.target.value);
            }}
            errorMessage={error}
            errorState={error !== undefined}
            type="tel"
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button htmlType="submit" classes={[styles.submitButton]}>
            Conferma
          </Button>
        </div>
      </form>
    </FullModal>
  );
};
