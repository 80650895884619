import { BodyText, Button, Icon, IconSize } from '@sbt-web/ui';
import React, { useState } from 'react';
import { Plus } from './Icons';
import styles from './index.module.scss';
import { PhoneDialog } from './PhoneDialog';

interface PhoneForwarderProps {
  phone?: string;
  onSetPhoneNumber: (phoneNumber: string) => void;
  isMobile: boolean;
}

interface RemoveButtonProps {
  isMobile: boolean;
  onClick: () => void;
}

const RemoveButton = ({ isMobile, onClick }: RemoveButtonProps) => {
  return isMobile ? (
    <Button
      type="text"
      onClick={onClick}
      size="medium"
      icon={<Icon name="Bin" iconSize={IconSize.MD} />}
      aria-label="elimina numero"
    />
  ) : (
    <Button size="small" type="text" onClick={onClick}>
      Elimina numero
    </Button>
  );
};

export const PhoneForwarder = ({
  phone,
  onSetPhoneNumber,
  isMobile,
}: PhoneForwarderProps) => {
  const [showPhoneDialog, setShowPhoneDialog] = useState<boolean>(false);

  const handlePhoneDialogSubmit = (phoneNumber: string) => {
    setShowPhoneDialog(false);
    onSetPhoneNumber(phoneNumber);
  };

  const handleDeletePhoneNumber = () => onSetPhoneNumber('');

  return (
    <>
      <div className={styles.marginTop}>
        {phone ? (
          <>
            <BodyText classes={[styles.recallText]}>
              Richiamami al {phone}
              <RemoveButton
                onClick={handleDeletePhoneNumber}
                isMobile={isMobile}
              />
            </BodyText>
            <BodyText classes={[styles.forwardText]}>
              Il tuo numero sarà inoltrato insieme al tuo messaggio
            </BodyText>
          </>
        ) : (
          <Button
            type="outline"
            icon={<Plus />}
            onClick={() => setShowPhoneDialog(true)}
            size="small"
          >
            Aggiungi telefono
          </Button>
        )}
      </div>

      <PhoneDialog
        isOpened={showPhoneDialog}
        onClose={() => setShowPhoneDialog(false)}
        onSubmit={handlePhoneDialogSubmit}
      />
    </>
  );
};
