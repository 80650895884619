import React, { useState } from 'react';
import { BodyText, Button, Icon } from '@sbt-web/ui';
import { useViewport } from '@sbt-web/hooks';

import { ShippingCosts } from 'server/detail-requests/ShippingCost';
import { FlowDialog } from '@client/components/Detail/FlowDialog';

import classes from './index.module.scss';

export type InfoBoxProps = {
  shippingCosts: ShippingCosts;
  showPayPal: boolean;
};

export const InfoBox = ({ shippingCosts, showPayPal }: InfoBoxProps) => {
  const { isMobile } = useViewport();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <section className={classes.infoBox}>
      <div className={classes.shippingCost}>
        <Icon name="shipping_promo" fillColor="currentColor" />
        <div className={classes.shippingCopy}>
          <BodyText size="small">
            {shippingCosts.type === 'self' || !shippingCosts.min.partial
              ? 'Spedizione: '
              : 'Spedizione a partire da '}
          </BodyText>
          <BodyText size="small" weight="semibold">
            {shippingCosts.type === 'self'
              ? shippingCosts.cost
              : shippingCosts.min.cost}
          </BodyText>
        </div>
      </div>
      <div className={classes.buyerProtection}>
        <Icon name="Shield" fillColor="currentColor" />
        <BodyText size="small">Protezione Acquisti</BodyText>
      </div>
      <div className={classes.paymentMethods}>
        <Icon name="Lock" fillColor="currentColor" />
        <BodyText size="small">Pagamento Sicuro</BodyText>
        <div className={classes.icons}>
          <img
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/payments/mastercard.png`}
            alt="Mastercard"
            width="20"
            height="21"
          />
          <img
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/payments/visa.png`}
            alt="Visa"
            width="26"
            height="21"
          />
          {showPayPal && (
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/payments/paypal.png`}
              alt="PayPal"
              width="30"
              height="21"
            />
          )}
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            setModalOpen(true);
          }}
          size="small"
          type="text"
          slim
        >
          Scopri di più
        </Button>
        <FlowDialog
          fullscreen={isMobile}
          opened={modalOpen}
          onCloseIntent={() => {
            setModalOpen(false);
          }}
          flowUrl={`${process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL}/transazioni/scopri-servizio-tuttosubito`}
        />
      </div>
    </section>
  );
};
