import { BodyText, Button, FullModal } from '@sbt-web/ui';
import React from 'react';
import styles from './index.module.scss';

interface ErrorDialogProps {
  isOpened: boolean;
  onClose: () => void;
  onRetry: () => void;
}

export const ErrorDialog = ({
  isOpened,
  onRetry,
  onClose,
}: ErrorDialogProps) => {
  return (
    <FullModal
      title="Messaggio non inviato"
      withClose
      open={isOpened}
      onClose={onClose}
      classes={[styles.errorDialog]}
    >
      <div className={styles.body}>
        <BodyText classes={[styles.description]}>
          C'è stato un errore e non è stato possibile inviare il tuo messaggio.
          Ti invitiamo a riprovare.
        </BodyText>

        <div className={styles.buttonWrapper}>
          <Button type="solid" onClick={onRetry}>
            Riprova
          </Button>
        </div>
      </div>
    </FullModal>
  );
};
