import type { AdItem } from '@sbt-web/networking';
import { ShareComponent } from '@sbt-web/share-button';
import { AD_SHARING_TITLE } from '@shared/constants';
import React from 'react';
import { AdAbuse } from '../AdAbuse';
import css from './index.module.scss';
import classNames from 'classnames';

interface Props {
  item: AdItem;
}

export const BottomBar: React.FunctionComponent<Props> = ({ item }) => {
  return (
    <div className={css['ad-bottom-container']}>
      <div className={css['ad-bottom__buttons']}>
        <ShareComponent
          type="text"
          adTrackInfo={{
            urn: item.urn,
            category: item.category,
            trackIds: {
              shareButton: () => {
                return 'sharing-icon-bottom';
              },
              closeModal: () => {
                return 'close-sharing-bottom-modal';
              },
              shareWithNativeApi: 'sharing-icon-bottom-customsocial',
              messenger: 'sharing-icon-bottom-mess',
              facebook: 'sharing-icon-bottom-facebook',
              whatsApp: 'sharing-icon-bottom-whatsapp',
              copyUrl: 'sharing-icon-bottom-copylink',
            },
          }}
          className={css['ad-bottom__share-btn']}
          text="Condividi"
          sharingTitle={AD_SHARING_TITLE}
          sharingUrl={() => {
            const canonicalLink = document.querySelector<HTMLLinkElement>(
              "link[rel='canonical']"
            );
            const link = canonicalLink?.href ?? window.location.href;
            return `${link}?utm_medium=referral&utm_source=subito&utm_campaign=free-engagement-ad_detail`;
          }}
        />
        <AdAbuse item={item} />
      </div>

      <p className={classNames(css['ad-bottom__ad-id'], 'caption book')}>
        ID: {item.urn.split(':')[4]}
      </p>
    </div>
  );
};

export default BottomBar;
