import { AdsAdDetailsBlock } from '@client/components/Adv/AdSense/adDetails/AdDetailsBlock';
import {
  AdsGAMPlacement,
  DesktopAdDetailGAMIds,
  MsiteAdDetailGAMIds,
} from '@client/components/Adv/GPT';
import { CardBlock } from '@sbt-web/ancillary-services';
import { getPublicUser } from '@sbt-web/auth';
import { AvailableIcons, SpecialCampaignBanner } from '@sbt-web/banners';
import { useViewport } from '@sbt-web/hooks';
import {
  CategoryId,
  FeatureURI,
  HebeCampaignPosition,
  PromoInfo,
} from '@sbt-web/networking';
import { Divider } from '@sbt-web/ui';
import { parseItalianNumber } from '@sbt-web/utils';
import flattenFeatures from '@shared/helpers/FlattenFeatures';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PaymentMethods, ShippingMethods } from '../TuttoSubitoInfo';
import { ShippingCosts } from 'server/detail-requests/ShippingCost';
import InternalLinksFooter from '../../SEO/InternalLinks/Footer';
import InternalLinksHeader from '../../SEO/InternalLinks/Header';
import type { Props as CommonProps } from './common-props';
import StickyBar from '../StickyBar';
import { getTransactionFlows } from '@shared/tools/transaction/utils';
import {
  AdvertiserInfoSection,
  BottomSection,
  DescriptionSection,
  FeatureListSection,
  GallerySection,
  GeneralInfoSection,
  MapSection,
  RelatedAds,
  SafetySection,
  SkyscraperSection,
} from './Layout';

import '@sbt-web/banners/style';
import css from './style/container.module.scss';
import gridStyles from './style/grid.module.scss';
import shippingStyles from './style/default-container.module.scss';
import NavigationRow from '../NavigationRow';
import { AdvDetailContext } from '@client/components/Adv/AdvAdDetailContext';
import { LazyPrestiPayBox } from '@client/components/Detail/PrestiPayBoxAncillaryService';

const dividerClasses = [
  gridStyles['detail-component'],
  gridStyles['section-divider'],
];

const defaultDesktopRightBlockRowSpan = 6;

type Props = CommonProps & {
  promo: PromoInfo | null;
  shippingCosts: ShippingCosts | null;
};

const areSafetyTipsActive = (categoryId: CategoryId) =>
  categoryId == CategoryId.ConsoleVideogiochi ||
  categoryId == CategoryId.Informatica ||
  categoryId == CategoryId.AudioVideo ||
  categoryId == CategoryId.Fotografia ||
  categoryId == CategoryId.Telefonia ||
  categoryId == CategoryId.Elettronica ||
  categoryId == CategoryId.CasaPersona ||
  categoryId == CategoryId.ArredamentoCasalinghi ||
  categoryId == CategoryId.GiardinoFaiDaTe ||
  categoryId == CategoryId.AbbigliamentoAccessori ||
  categoryId == CategoryId.TuttoBambini ||
  categoryId == CategoryId.SportsHobby ||
  categoryId == CategoryId.MusicaFilm ||
  categoryId == CategoryId.LibriRiviste ||
  categoryId == CategoryId.StrumentiMusicali ||
  categoryId == CategoryId.Sports ||
  categoryId == CategoryId.Biciclette ||
  categoryId == CategoryId.Collezionismo ||
  categoryId == CategoryId.Animali;

export function DefaultContainer({
  ad,
  internalLinks,
  shop,
  favoriteCounter,
  advertiserProfile,
  trustInfo,
  promo,
  shippingCosts,
}: Props) {
  const { isMobile, isDesktop } = useViewport();

  const itemPrice =
    ad.features[FeatureURI.Price]?.values?.[0]?.key != null
      ? parseItalianNumber(ad.features[FeatureURI.Price]?.values?.[0]?.key)
      : null;

  const features = flattenFeatures(ad.features, ad.geo);

  const mapDetails = ad.geo.map;

  const hasMap = mapDetails?.longitude != null && mapDetails?.latitude != null;

  const isSold = ad.features[FeatureURI.TransactionStatus] != undefined;

  // This is an overly-complex workaround because `getPublicUser` can't be
  // called on the server
  const [userId, setUserId] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (__BROWSER__) {
      setUserId(getPublicUser()?.id);
    }
  }, []);

  const transactionFlows = getTransactionFlows(
    ad.features,
    ad.advertiser,
    userId
  );

  const thereIsNoPlaceForContactButtonElsewhere = transactionFlows.length === 2;
  const userIsSeller = userId === ad.advertiser.userId;

  const showShippingSection = !isSold && shippingCosts !== null;
  const showPaymentSection =
    showShippingSection &&
    itemPrice != null &&
    itemPrice > 0 &&
    itemPrice <= 2000;

  const hasSafetyTips = areSafetyTipsActive(ad.category.id);

  const [desktopRightBlockRowSpan, setDesktopRightBlockRowSpan] = useState(
    () => {
      let result = defaultDesktopRightBlockRowSpan;

      if (features.length > 0) {
        result += 2;
      }

      if (hasMap) {
        result += 1;
      }

      if (isSold && userIsSeller) {
        result -= 1;
      }

      // The TuttoSubito section is only rendered for shippable ads.
      if (showShippingSection) {
        result += 2;
      }

      if (showPaymentSection) {
        result += 2;
      }

      if (hasSafetyTips) {
        result += 1;
      }

      return result;
    }
  );

  const increaseRowSpan = useCallback(
    () => setDesktopRightBlockRowSpan((span) => span + 1),
    []
  );

  const promoHasCountdown = !!promo?.content.countdown?.alternativeSubtitle;
  const promoSubtitle = promoHasCountdown
    ? promo?.content?.countdown?.alternativeSubtitle
    : promo?.content.subtitle;

  const advContext = useContext(AdvDetailContext);
  const { showBannerBelowGallery } = advContext;

  return (
    <>
      {internalLinks !== null ? (
        <div
          className={classnames(
            gridStyles['detail-component'],
            gridStyles['navigation-detail-container']
          )}
        >
          <InternalLinksHeader links={internalLinks.header} />
        </div>
      ) : null}
      <div className={css['outer-ad-container']}>
        <div
          className={classnames(
            css['inner-ad-container'],
            gridStyles['detail-container']
          )}
          style={{ '--right-block-row-span': desktopRightBlockRowSpan }}
        >
          <section
            className={classnames([
              gridStyles['detail-component'],
              gridStyles['navigation-detail-container'],
            ])}
          >
            <NavigationRow item={ad} />
          </section>
          <GallerySection
            classes={[gridStyles['detail-component'], gridStyles['gallery']]}
            ad={ad}
          />
          {isMobile && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={MsiteAdDetailGAMIds.ANCHOR}
              fixedHeight={50}
            />
          )}
          <div
            className={classnames(
              gridStyles['detail-component'],
              gridStyles['right-container']
            )}
          >
            <GeneralInfoSection
              classes={[
                gridStyles['ad-info-container'],
                gridStyles['ad-info-override'],
              ]}
              ad={ad}
              shop={shop}
              favoriteCounter={favoriteCounter}
              advertiserProfile={advertiserProfile}
              shippingCosts={shippingCosts}
              trustInfo={trustInfo}
              promo={promo}
              showContactButton={thereIsNoPlaceForContactButtonElsewhere}
            />
            {isDesktop && (
              <SkyscraperSection item={ad} classes={[gridStyles.skyscraper]} />
            )}
          </div>
          {!isSold && isDesktop && promo && promoSubtitle && (
            <div className={gridStyles['detail-component']}>
              <SpecialCampaignBanner
                title={promo?.content.title}
                description={promoSubtitle}
                iconName={promo?.content.assets.resourceName as AvailableIcons}
                countdownTo={promoHasCountdown ? promo?.endDate : undefined}
                alternativeExternalIconURL={promo?.content.assets.resourceUrl}
              />
            </div>
          )}

          {showBannerBelowGallery && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={DesktopAdDetailGAMIds.BELOW_GALLERY}
            />
          )}

          <DescriptionSection
            ad={ad}
            classes={[gridStyles['detail-component'], gridStyles.description]}
          />
          <Divider spacing="null" classes={dividerClasses} />
          {!isDesktop && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={MsiteAdDetailGAMIds.BOX1}
              fixedHeight={250}
            />
          )}
          {features.length > 0 && (
            <FeatureListSection
              features={features}
              classes={[gridStyles['detail-component']]}
            />
          )}
          {hasMap && (
            <MapSection
              classes={[gridStyles['detail-component'], gridStyles.map]}
              map={mapDetails}
            />
          )}
          {showShippingSection && (
            <>
              <Divider spacing="null" classes={dividerClasses} />

              <section
                className={classnames(
                  gridStyles['detail-component'],
                  shippingStyles['shipping-details-section']
                )}
              >
                <ShippingMethods costs={shippingCosts} />
              </section>

              {showPaymentSection && (
                <>
                  <Divider spacing="null" classes={dividerClasses} />

                  <section className={gridStyles['detail-component']}>
                    <PaymentMethods />
                  </section>
                </>
              )}
            </>
          )}
          <div
            className={classnames(gridStyles['detail-component'], 'card-block')}
          >
            <CardBlock
              padding
              background
              pulseInstance={getOrCreatePulse()}
              bannerButtonType="outline"
              item={ad}
              remoteData={prepareRemoteDataAdDetail(
                HebeCampaignPosition.BELOW_FEATURES,
                ad,
                isMobile
              )}
            />
          </div>

          {isMobile ? (
            <section className={gridStyles['detail-component']}>
              <LazyPrestiPayBox
                price={ad.features['/price']}
                assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
                item={ad}
                pulseInstance={getOrCreatePulse()}
              />
            </section>
          ) : null}

          <Divider spacing="null" classes={dividerClasses} />
          <AdvertiserInfoSection
            classes={[gridStyles['detail-component']]}
            item={ad}
            shop={shop}
            advertiserProfile={advertiserProfile}
            trustInfo={trustInfo}
          />
          {hasSafetyTips && (
            <SafetySection
              classes={[gridStyles['detail-component']]}
              category={ad.category.id}
              urn={ad.urn}
            />
          )}
          <RelatedAds
            item={ad}
            className={gridStyles['detail-component']}
            onActualRender={increaseRowSpan}
          />
        </div>

        {!isMobile && <AdsAdDetailsBlock listingCategory={ad.category.id} />}

        {isMobile || (isSold && userIsSeller) ? null : <StickyBar item={ad} />}

        {!isDesktop && <AdsGAMPlacement id={MsiteAdDetailGAMIds.BOX2} />}
      </div>

      <BottomSection item={ad} />
      {internalLinks !== null ? (
        <InternalLinksFooter links={internalLinks.footer} />
      ) : null}

      {isDesktop && <div id="apn_body" />}
    </>
  );
}

export default DefaultContainer;
