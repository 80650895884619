import { AdsAdDetailsBlock } from '@client/components/Adv/AdSense/adDetails/AdDetailsBlock';
import {
  AdsGAMPlacement,
  DesktopAdDetailGAMIds,
  MsiteAdDetailGAMIds,
} from '@client/components/Adv/GPT';
import { CardBlock } from '@sbt-web/ancillary-services';
import { useViewport } from '@sbt-web/hooks';
import {
  CategoryId,
  HebeCampaignPosition,
  ItemCategory,
  ItemFeature,
  ItemGeo,
  KeyValuePair,
} from '@sbt-web/networking';
import { Divider, Headline6, StaticChip, Subhead } from '@sbt-web/ui';
import { evalBoolFeature, isBrowser } from '@sbt-web/utils';
import flattenFeatures from '@shared/helpers/FlattenFeatures';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import InternalLinksFooter from '../../SEO/InternalLinks/Footer';
import InternalLinksHeader from '../../SEO/InternalLinks/Header';
import FeatureList, { Feature } from '../FeatureList';
import { LoanProps, provinceCrediteAgricoleList } from '../Skyscraper';
import StickyBar from '../StickyBar';
import {
  AdvertiserInfoSection,
  BottomSection,
  DescriptionSection,
  GallerySection,
  GeneralInfoSection,
  MainDataSection,
  RelatedAds,
  SafetySection,
  SkyscraperSection,
  YatmoMapSection,
} from './Layout';
import * as FeatureConfig from './RealEstateConstants';
import type { Props as CommonProps } from './common-props';

import featureSectionStyles from './Layout/style/feature-list-section.module.scss';
import css from './style/container.module.scss';
import gridStyles from './style/grid.module.scss';
import NavigationRow from '../NavigationRow';
import { AdvDetailContext } from '@client/components/Adv/AdvAdDetailContext';

const defaultDesktopRightBlockRowSpan = 7;

const CreditAgricoleBox = dynamic<LoanProps>(() =>
  import('@sbt-web/ancillary-services').then(
    (module) => module.CreditAgricoleBox
  )
);

const CreditAgricoleBlock = dynamic<LoanProps>(() =>
  import('@sbt-web/ancillary-services').then(
    (module) => module.CreditAgricoleBlock
  )
);

const shouldRenderCreditAgricoleBoxMsite = ({
  geo,
  price,
  type,
}: {
  geo: ItemGeo;
  price: ItemFeature;
  type: KeyValuePair;
}): boolean => {
  const priceKey = price?.values?.[0]?.key;
  const cityName = geo?.city?.shortName;
  const typeValue = type.value.toLocaleLowerCase();

  return (
    (priceKey &&
      parseInt(priceKey) >= 40000 &&
      parseInt(priceKey) <= 2000000 &&
      cityName &&
      provinceCrediteAgricoleList.includes(cityName) &&
      typeValue === 'in vendita') ||
    false
  );
};

function RealEstateContainer({
  ad,
  internalLinks,
  shop,
  favoriteCounter,
  advertiserProfile,
  trustInfo,
  newCreditAgricoleToggle,
}: CommonProps) {
  const { isMobile, isDesktop } = useViewport();

  const hasFeatures = Object.keys(ad.features).length > 0;

  const mapDetails = ad.geo.map;

  const hasMap = mapDetails?.longitude != null && mapDetails?.latitude != null;

  const hasSafetyTips = ad.category.id === CategoryId.CaseVacanza;

  const shouldRenderNewCreditAgricoleDesktop =
    newCreditAgricoleToggle && isDesktop && shouldRenderCreditAgricoleBoxMsite;

  const [desktopRightBlockRowSpan, setDesktopRightBlockRowSpan] = useState(
    () => {
      let result = defaultDesktopRightBlockRowSpan;

      if (hasFeatures) {
        result += 2;
      }

      if (hasMap) {
        result += 1;
      }

      if (hasSafetyTips) {
        result += 1;
      }

      if (shouldRenderNewCreditAgricoleDesktop) {
        result += 2;
      }

      return result;
    }
  );
  const increaseRowSpan = useCallback(
    () => setDesktopRightBlockRowSpan((span) => span + 1),
    []
  );

  const advContext = useContext(AdvDetailContext);
  const { showBannerBelowGallery } = advContext;

  return (
    <>
      {internalLinks !== null ? (
        <div
          className={classnames(
            gridStyles['detail-component'],
            gridStyles['navigation-detail-container']
          )}
        >
          <InternalLinksHeader links={internalLinks.header} />
        </div>
      ) : null}
      <div className={css['outer-ad-container']}>
        <div
          className={classnames(
            css['inner-ad-container'],
            gridStyles['detail-container']
          )}
          style={{ '--right-block-row-span': desktopRightBlockRowSpan }}
        >
          <section
            className={classnames([
              gridStyles['detail-component'],
              gridStyles['navigation-detail-container'],
            ])}
          >
            <NavigationRow item={ad} />
          </section>

          <GallerySection
            classes={[gridStyles['detail-component'], gridStyles.gallery]}
            ad={ad}
          />

          {isMobile && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={MsiteAdDetailGAMIds.ANCHOR}
              fixedHeight={50}
            />
          )}

          {showBannerBelowGallery && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={DesktopAdDetailGAMIds.BELOW_GALLERY}
            />
          )}

          <div
            className={classnames(
              gridStyles['detail-component'],
              gridStyles['right-container']
            )}
          >
            <GeneralInfoSection
              classes={[
                gridStyles['ad-info-container'],
                gridStyles['ad-info-override'],
              ]}
              ad={ad}
              shop={shop}
              promo={null}
              favoriteCounter={favoriteCounter}
              advertiserProfile={advertiserProfile}
              trustInfo={trustInfo}
              shippingCosts={null}
            />

            {isDesktop ? (
              <SkyscraperSection
                item={ad}
                classes={[gridStyles.skyscraper]}
                newCreditAgricoleToggle={newCreditAgricoleToggle}
              />
            ) : null}
          </div>

          <MainDataSection
            classes={[gridStyles['detail-component']]}
            onActualRender={increaseRowSpan}
          />

          <DescriptionSection
            ad={ad}
            classes={[gridStyles['detail-component'], gridStyles.description]}
          />

          {isDesktop ? null : (
            <>
              <Divider
                spacing="null"
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['section-divider'],
                ]}
              />
              <AdsGAMPlacement
                classes={[gridStyles['detail-component']]}
                id={MsiteAdDetailGAMIds.BOX1}
                fixedHeight={250}
              />
            </>
          )}

          {hasFeatures ? (
            <>
              <Divider
                spacing="null"
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['section-divider'],
                ]}
              />
              <RealEstateFeatureSection
                adCategory={ad.category}
                adType={ad.type.key}
                rawFeatures={ad.features}
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['feature-list'],
                ]}
              />
            </>
          ) : null}

          {hasMap ? (
            <>
              <Divider
                spacing="null"
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['section-divider'],
                ]}
              />
              <YatmoMapSection
                classes={[gridStyles['detail-component'], gridStyles.map]}
                map={mapDetails}
              />
            </>
          ) : null}

          {shouldRenderNewCreditAgricoleDesktop ? (
            <>
              <Divider
                spacing="null"
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['section-divider'],
                ]}
              />
              <section className={gridStyles['detail-component']}>
                <CreditAgricoleBlock
                  item={ad}
                  price={ad.features['/price']}
                  assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
                  pulseInstance={getOrCreatePulse()}
                />
              </section>
            </>
          ) : null}

          <div className={gridStyles['detail-component']}>
            <CardBlock
              padding
              pulseInstance={getOrCreatePulse()}
              background
              bannerButtonType="outline"
              item={ad}
              remoteData={prepareRemoteDataAdDetail(
                HebeCampaignPosition.BELOW_FEATURES,
                ad,
                isMobile
              )}
            />
          </div>

          {shouldRenderCreditAgricoleBoxMsite({
            geo: ad.geo,
            price: ad.features['/price'],
            type: ad.type,
          }) && isMobile ? (
            <section className={gridStyles['detail-component']}>
              <CreditAgricoleBox
                item={ad}
                price={ad.features['/price']}
                assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
                pulseInstance={getOrCreatePulse()}
              />
            </section>
          ) : null}

          <Divider
            spacing="null"
            classes={[
              gridStyles['detail-component'],
              gridStyles['section-divider'],
            ]}
          />

          <AdvertiserInfoSection
            classes={[gridStyles['detail-component']]}
            item={ad}
            shop={shop}
            trustInfo={null}
            advertiserProfile={advertiserProfile}
          />

          {hasSafetyTips ? (
            <SafetySection
              classes={[gridStyles['detail-component']]}
              category={ad.category.id}
              urn={ad.urn}
            />
          ) : null}

          <RelatedAds
            item={ad}
            className={gridStyles['detail-component']}
            onActualRender={increaseRowSpan}
          />
        </div>

        {!isMobile && <AdsAdDetailsBlock listingCategory={ad.category.id} />}

        {!isMobile && <StickyBar item={ad} />}

        {isDesktop ? null : <AdsGAMPlacement id={MsiteAdDetailGAMIds.BOX2} />}
      </div>

      <BottomSection item={ad} />

      {internalLinks !== null ? (
        <InternalLinksFooter links={internalLinks.footer} />
      ) : null}

      {isDesktop ? <div id="apn_body" /> : null}
    </>
  );
}

type FeatureCollection = { [uri: string]: ItemFeature };

interface FeatureSectionProps {
  adCategory: ItemCategory;
  adType: string;
  rawFeatures: FeatureCollection;
  classes?: string[];
}

const fillCoreFeatures = (
  adFeatures: FeatureCollection,
  adCategory: ItemCategory,
  adType: string
): Feature[] => {
  const categoryTypeKey = `${adCategory.id}_${adType}`;

  const categoryFeatures =
    FeatureConfig.coreFeaturesByCategoryAndType.get(categoryTypeKey) ?? [];

  const filledFeatures: Feature[] = categoryFeatures
    .filter((uri) => {
      const notInAdFeatures = !(uri in adFeatures);

      // If we don't have a label we can't make a placeholder.
      const labelExists = FeatureConfig.coreFeatureLabels.has(uri);

      return notInAdFeatures && labelExists;
    })
    .map((f) => {
      return {
        label: FeatureConfig.coreFeatureLabels.get(f),
        value: '-',
      };
    });

  const flatFeatures = flattenFeatures(adFeatures);

  return flatFeatures.concat(filledFeatures);
};

const fillEnergyFeatures = (adFeatures: FeatureCollection): Feature[] => {
  if (Object.keys(adFeatures).length === 0) {
    return [];
  }

  const energyFeatureURIs = FeatureConfig.energyFeatureLabels.keys();

  const filledFeatures: Feature[] = [];

  for (const featureUri of Array.from(energyFeatureURIs)) {
    const notInAdFeatures = !(featureUri in adFeatures);

    if (notInAdFeatures) {
      filledFeatures.push({
        label: FeatureConfig.energyFeatureLabels.get(featureUri),
        value: '-',
      });
    }
  }

  const flatFeatures = flattenFeatures(adFeatures);

  return flatFeatures.concat(filledFeatures);
};

const sortFeatures = (
  config: Map<string, number>,
  left: Feature,
  right: Feature
): number => {
  const leftIndex = left.label && config.get(left.label);
  const rightIndex = right.label && config.get(right.label);

  if (leftIndex && rightIndex) {
    return leftIndex - rightIndex;
  } else {
    // This should never happen but it is a failsafe.
    return 0;
  }
};

const sortCoreFeatures = sortFeatures.bind(
  null,
  FeatureConfig.coreFeatureSortOrder
);
const sortDetailFeatures = sortFeatures.bind(
  null,
  FeatureConfig.detailFeatureSortOrder
);
const sortEnergyFeatures = sortFeatures.bind(
  null,
  FeatureConfig.energyFeatureSortOrder
);

function RealEstateFeatureSection(props: FeatureSectionProps) {
  const [isGapSupported, setIsGapSupported] = useState(true);

  useEffect(() => {
    if (!isBrowser()) {
      setIsGapSupported(true);
    } else {
      // create flex container with row-gap set
      const flex = document.createElement('div');
      flex.style.display = 'flex';
      flex.style.flexDirection = 'column';
      flex.style.rowGap = '1px';

      // create two elements inside it
      flex.appendChild(document.createElement('div'));
      flex.appendChild(document.createElement('div'));

      // append to the DOM (needed to obtain scrollHeight)
      document.body.appendChild(flex);
      const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
      flex.parentNode?.removeChild(flex);

      setIsGapSupported(isSupported);
    }
  }, [setIsGapSupported]);

  const { rawFeatures, classes = [], adCategory, adType } = props;

  const detailFeatures: Record<string, ItemFeature> = {},
    energyFeatures: Record<string, ItemFeature> = {},
    remainingFeatures: Record<string, ItemFeature> = {};

  for (const uri in rawFeatures) {
    if (FeatureConfig.detailFeatureLabels.has(uri)) {
      const detailFeature = rawFeatures[uri];

      if (evalBoolFeature(detailFeature)) {
        detailFeatures[uri] = detailFeature;
      } else {
        continue;
      }
    } else if (FeatureConfig.energyFeatureLabels.has(uri)) {
      energyFeatures[uri] = rawFeatures[uri];
    } else {
      remainingFeatures[uri] = rawFeatures[uri];
    }
  }

  const coreFeatures = fillCoreFeatures(remainingFeatures, adCategory, adType);
  const coreDisplayFeatures = coreFeatures.sort(sortCoreFeatures);

  const detailDisplayFeatures =
    flattenFeatures(detailFeatures).sort(sortDetailFeatures);

  const filledEnergyFeatures = fillEnergyFeatures(energyFeatures);
  const energyDisplayFeatures = filledEnergyFeatures.sort(sortEnergyFeatures);

  if (
    coreDisplayFeatures.length === 0 &&
    detailDisplayFeatures.length === 0 &&
    energyDisplayFeatures.length === 0
  ) {
    return null;
  }

  return (
    <section
      id="feature-section"
      className={classnames(...classes, gridStyles['detail-component'])}
    >
      <Headline6 element="h2" classes={[featureSectionStyles['section-title']]}>
        Caratteristiche
      </Headline6>

      {/* FeatureList renders null if there are no features */}
      <FeatureList features={coreDisplayFeatures} />

      {detailDisplayFeatures.length > 0 ? (
        <>
          <Subhead
            element="p"
            classes={[featureSectionStyles['section-subtitle']]}
          >
            Dettagli
          </Subhead>
          <div
            id={featureSectionStyles['detail-chip-container']}
            className={classnames({
              [featureSectionStyles['gap-fallback']]: !isGapSupported,
            })}
          >
            {detailDisplayFeatures.map((f) => (
              <StaticChip key={f.label} size="medium">
                {f.label}
              </StaticChip>
            ))}
          </div>
        </>
      ) : null}

      {energyDisplayFeatures.length > 0 ? (
        <>
          <Subhead
            element="p"
            classes={[featureSectionStyles['section-subtitle']]}
          >
            Energia e riscaldamento
          </Subhead>

          <FeatureList features={energyDisplayFeatures} singleColumn />
        </>
      ) : null}
    </section>
  );
}

export default RealEstateContainer;

// Exported for testing
export { RealEstateFeatureSection };
