import React from 'react';
import { Button } from '@sbt-web/ui';

import classes from './index.module.scss';

export const ContactButton = () => (
  <Button
    classes={[classes.button]}
    type="outline"
    onClick={() => {
      window.dispatchEvent(new CustomEvent('subito:AdView:BtnAuthorReply'));
    }}
  >
    Contatta
  </Button>
);
