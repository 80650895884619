import React from 'react';
import ErrorBoundary from '@sbt-web/error-boundary';
import { BodyText, Button, Caption, Headline6, Subhead } from '@sbt-web/ui';
import type { AdItem, OpeningTime } from '@sbt-web/networking';
import Socialization from './Socialization';
import { OpeningTimeTable } from './OpeningTimeTable';
import { shopLinkClick } from '@client/components/Detail/AdvertiserInfo/AdDetailCompanyPro/tracking';
import { escapeUrlWithProtocol } from '@shared/helpers/EscapeUrl';
import { CompanyBadge } from '@client/components/Detail/UserDetails';
import SocializationB from './SocializationB';

import classes from './index.module.scss';

interface AdDetailCompanyProProps {
  name: string;
  item: AdItem;
  adsCount?: number;
  logo?: string;
  address?: string;
  url?: string;
  website?: string;
  shopId?: number;
  openingTime?: OpeningTime;
  showContactButton?: boolean;
  makeAnOfferABTestVariant?: 'A' | 'B';
}

type ShopWebsiteProps = Pick<AdDetailCompanyProProps, 'website' | 'item'>;

const Placeholder = () => (
  <svg viewBox="0 0 48 48" width="38%" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.77 15.5c0 1.657 1.377 3 3.076 3 1.654 0 3.004-1.273 3.074-2.869L38.02 9H9.981L7.08 15.631c.07 1.596 1.42 2.869 3.074 2.869 1.7 0 3.077-1.343 3.077-3 0-.828.689-1.5 1.538-1.5.85 0 1.539.672 1.539 1.5 0 1.657 1.377 3 3.077 3 1.699 0 3.077-1.343 3.077-3 0-.828.688-1.5 1.538-1.5.85 0 1.538.672 1.538 1.5 0 1.657 1.378 3 3.077 3 1.7 0 3.077-1.343 3.077-3 0-.828.69-1.5 1.539-1.5.85 0 1.538.672 1.538 1.5zM12 21.225V39h4.465c-.007-7.412 2.452-11.5 7.535-11.5s7.542 4.088 7.535 11.5H36V21.225a6.175 6.175 0 0 1-2.77-1.756 6.208 6.208 0 0 1-4.615 2.031A6.208 6.208 0 0 1 24 19.469a6.208 6.208 0 0 1-4.615 2.031 6.208 6.208 0 0 1-4.616-2.031A6.175 6.175 0 0 1 12 21.225zm-3 .17c-2.835-.525-4.981-2.945-5-5.857-.005-.213.035-.431.126-.64l3.238-7.4A2.5 2.5 0 0 1 9.654 6h28.692a2.5 2.5 0 0 1 2.29 1.498l3.238 7.4c.091.209.131.427.126.64-.019 2.912-2.165 5.332-5 5.857V39.5a2.5 2.5 0 0 1-2.5 2.5h-6.292l-.065-.001a1.7 1.7 0 0 1-1.633-1.764c.26-6.793-1.306-9.735-4.51-9.735-3.204 0-4.77 2.942-4.51 9.735l.002.065a1.7 1.7 0 0 1-1.7 1.7H11.5A2.5 2.5 0 0 1 9 39.5V21.395z"
      fill="#AEB9C6"
      fillRule="evenodd"
    />
  </svg>
);

const ShopWebsite = ({ website, item }: ShopWebsiteProps) => (
  <div className={classes.website}>
    <Subhead>Sito web</Subhead>
    <a
      href={website}
      rel="nofollow external noopener noreferrer"
      className={classes.website_link}
      aria-label="Vai al sito esterno dello shop"
      onClick={() => {
        shopLinkClick(item);
      }}
    >
      <Caption element="span" weight="book">
        {website}
      </Caption>
    </a>
  </div>
);

export const AdDetailCompanyPro = ({
  name,
  item,
  adsCount,
  logo,
  address,
  url,
  website,
  shopId,
  openingTime,
  showContactButton,
  makeAnOfferABTestVariant = 'A',
}: AdDetailCompanyProProps) => (
  <ErrorBoundary>
    <section className={classes['shop-box-bottom']}>
      <div className={classes.main_data}>
        <div className={classes.logo}>
          <figure className={classes.shop_image}>
            {logo ? <img src={logo} alt={name} /> : <Placeholder />}
          </figure>
          <div className={classes.company_name}>
            <CompanyBadge categoryId={item.category.id} />
            <Headline6>{name}</Headline6>
          </div>
        </div>
        {address && (
          <div className={classes.address}>
            <Subhead>Indirizzo</Subhead>
            <BodyText classes={[classes.body_text]}>{address}</BodyText>
          </div>
        )}
        {website && (
          <ShopWebsite website={escapeUrlWithProtocol(website)} item={item} />
        )}
        {openingTime && <OpeningTimeTable openingTime={openingTime} />}
      </div>

      {shopId &&
        (makeAnOfferABTestVariant === 'B' ? (
          <SocializationB adsCount={adsCount} url={url} />
        ) : (
          <Socialization adsCount={adsCount} url={url} />
        ))}
      {showContactButton && (
        <Button
          classes={[classes.contactButton]}
          type="outline"
          size="small"
          onClick={() => {
            window.dispatchEvent(
              new CustomEvent('subito:AdView:BtnAuthorReply')
            );
          }}
        >
          Contatta
        </Button>
      )}
    </section>
  </ErrorBoundary>
);
