import type { RootState } from '@reducers/index';
import { formatMainData, ItemMainFeature } from '@sbt-web/utils';
import { Divider } from '@sbt-web/ui';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import MainData from '../../MainData';
import type { OptionalSectionProps } from './section';
import { useOptionalSection } from './utils';
import type { AdItem } from '@sbt-web/networking';

interface StateProps {
  features: ItemMainFeature[];
}

type Props = OptionalSectionProps & StateProps;

export function MainDataSection({
  features,
  classes = [],
  onActualRender,
}: Props) {
  // TODO: This needs to be calculated in the Container
  const rendering = features && features.length > 0;
  useOptionalSection(rendering, onActualRender);

  if (!rendering) {
    return null;
  }

  return (
    <section className={classnames(...classes, 'main-data')}>
      <MainData features={features} />

      <Divider spacing="null" />
    </section>
  );
}

const mapStateToProps = (state: RootState): StateProps => ({
  features: formatMainData(state.detail.item as AdItem, {
    short: false,
    assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE_URL,
  }),
});

export default connect(mapStateToProps)(MainDataSection);
