import * as React from 'react';
import type { AdItem, ProfileQueryData } from '@sbt-web/networking';
import { DEFAULT_ADVERTISER_NAME } from '@shared/constants';
import { AdDetailCompanyPro } from '../AdDetailCompanyPro';

export interface AdvertiserInfoCompanyProps {
  item: AdItem;
  advertiserProfile: ProfileQueryData | null;
  showContactButton?: boolean;
}

export const AdvertiserInfoCompany = ({
  advertiserProfile,
  item,
  showContactButton,
}: AdvertiserInfoCompanyProps) => (
  <AdDetailCompanyPro
    name={advertiserProfile?.username || DEFAULT_ADVERTISER_NAME}
    item={item}
    showContactButton={showContactButton}
  />
);
