import type {
  AdItem,
  ProfileQueryData,
  Shop,
  TrustProfileInfo,
} from '@sbt-web/networking';
import React from 'react';
import { AdvertiserInfoCompany } from './AdvertiserInfoCompany';
import { AdvertiserInfoPrivate } from './AdvertiserInfoPrivate';
import { AdvertiserType } from '@sbt-web/networking';
import { AdvertiserInfoPro } from './AdvertiserInfoPro';

interface Props {
  item: AdItem;
  shop: Shop | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  showContactButton?: boolean;
  makeAnOfferABTestVariant?: 'A' | 'B';
}

const AdvertiserInfo: React.FunctionComponent<Props> = ({
  shop,
  advertiserProfile,
  trustInfo,
  item,
  showContactButton = false,
  makeAnOfferABTestVariant = 'A',
}) => {
  return (
    <>
      {item.advertiser.type === AdvertiserType.Private && (
        <AdvertiserInfoPrivate
          advertiserProfile={advertiserProfile}
          trustInfo={trustInfo}
          item={item}
          showContactButton={showContactButton}
        />
      )}
      {item.advertiser.type === AdvertiserType.ImpresaPiu && (
        <AdvertiserInfoPro
          shop={shop}
          advertiserProfile={advertiserProfile}
          item={item}
          makeAnOfferABTestVariant={makeAnOfferABTestVariant}
        />
      )}
      {item.advertiser.type === AdvertiserType.Company && (
        <AdvertiserInfoCompany
          advertiserProfile={advertiserProfile}
          item={item}
          showContactButton={showContactButton}
        />
      )}
    </>
  );
};

export default AdvertiserInfo;
