import type { ItemMainFeature } from '@sbt-web/utils';
import { Caption, Headline6 } from '@sbt-web/ui';
import cn from 'classnames';
import React from 'react';
import classes from './MainData.module.scss';

interface Props {
  features: ItemMainFeature[];
}

export function MainData({ features }: Props) {
  const featureList = features.map((feature) => {
    if (!feature?.label && !feature?.placeholder) {
      return null;
    }

    return (
      <div className={classes['main-feature']} key={feature.iconURL}>
        <img
          src={feature.iconURL}
          alt={feature.placeholder}
          width="16"
          height="16"
          loading="lazy"
          decoding="async"
        />
        <Caption
          classes={[
            cn({
              [classes.label]: !!feature.label,
              [classes.placeholder]: !feature.label && !!feature.placeholder,
            }),
          ]}
        >
          {feature.label || `- (${feature.placeholder})` || ''}
        </Caption>
      </div>
    );
  });

  return (
    <>
      <Headline6 classes={[classes.title]}>Dati principali</Headline6>
      <div className={classes['main-features-container']}>{featureList}</div>
    </>
  );
}
