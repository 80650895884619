import { CardBlock } from '@sbt-web/ancillary-services';
import { useViewport } from '@sbt-web/hooks';
import { AdItem, HebeCampaignPosition, CategoryId } from '@sbt-web/networking';
import { BodyText, Headline6 } from '@sbt-web/ui';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { ReplacePhoneNumberWithButton } from './components/ReplacePhoneNumberWithPhoneButton';
import { isPro } from '@shared/models/Advertiser';
import React from 'react';
import classes from './AdDescription.module.scss';
import { getCopy } from './copy';

interface Props {
  ad: AdItem;
}

const REPLACE_PHONE_NUMBER_ENABLED_CATEGORIES = [
  CategoryId.Auto,
  CategoryId.Moto,
];

const AdDescription: React.FC<Props> = ({ ad }) => {
  const { isMobile } = useViewport();
  const { body } = ad;

  if (!body) {
    return null;
  }

  const isProAdvertiser = isPro(ad.advertiser.type);
  const isCategoryMotori = isProAdvertiser
    ? REPLACE_PHONE_NUMBER_ENABLED_CATEGORIES.includes(ad.category.id)
    : null;

  const bodyTextComponents =
    isProAdvertiser && isCategoryMotori ? (
      <ReplacePhoneNumberWithButton ad={ad} />
    ) : (
      body
    );

  const copy = getCopy(ad);

  return (
    <>
      <Headline6 classes={[classes['description-title']]} element="h2">
        Descrizione
      </Headline6>

      <BodyText preserveNewLines classes={[classes['description']]}>
        {bodyTextComponents}
      </BodyText>

      {copy != null ? (
        <BodyText size="small" classes={[classes['copy']]}>
          {copy}
        </BodyText>
      ) : null}

      <CardBlock
        padding
        pulseInstance={getOrCreatePulse()}
        background
        bannerButtonType="outline"
        item={ad}
        remoteData={prepareRemoteDataAdDetail(
          HebeCampaignPosition.BELOW_DESCRIPTION,
          ad,
          isMobile
        )}
        classes={[classes['card-block-container']]}
      />
    </>
  );
};

export default AdDescription;
